<template>
    <div class="collection h-font-lg" id="collection">
        <div class="collection-title">
            身份证采集
        </div>
        <div class="base h-group car-des">
            <div class="h-cell h-cell--center">
                <div class="h-cell__title" style="font-size:20px">{{ plateNoName }}</div>
            </div>
            <div class="h-cell__content">
                <div class="warn">
                <i class="van-icon van-icon-warning" /> {{ riskDesc }}
                </div>
            </div>
        </div>
        <div class="holder h-group">
            <div class="h-cell h-cell--center">
                <div class="h-cell__title">
                    <van-icon :name="require('./image/holder.png')" size="18px" />身份信息
                </div>
                <div class="h-cell__value">
                    <span v-if="msgObj.holder||msgObj.insured" class="msg_warn">{{msgObj.holder+msgObj.insured}}项信息待完善</span>
                    <span v-else class="msg_success">信息已完善</span>
                </div>
                </div>
                <div class="h-cell__content">
                <van-row gutter="10">
                    <van-col v-if="resData.holder && resData.holder.custType === '0'" span="12" class="holder_bg holder_bg--holder" @click="toPath('/miniprogram/quote/cardCollection/holder')">
                    <div>
                        <span class="h-font-sm" style="color:#969799">投保人</span><br/>
                        <span class="h-font-xl h-font-bold h-font-people">{{ holderInfo.name || 'xxx' }}</span></div>
                    </van-col>
                    <van-col v-if="resData.insured && resData.insured.custType === '0'" span="12" class="holder_bg holder_bg--insured" @click="toPath('/miniprogram/quote/cardCollection/insured')">
                    <div>
                        <span class="h-font-sm" style="color:#969799">被保人</span><br/>
                        <span class="h-font-xl h-font-bold h-font-people">{{ insuredInfo.name || 'xxx' }}</span></div>
                    </van-col>
                </van-row>
                </div>
        </div>
        <div class="quote-result_bnt">
            <div class="quote-result_bnt--fixed" >
                <van-button type="primary" block round @click="collection">采集</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { request } from "@/api/service";
import dayjs from 'dayjs'
import mixinBase from '../common/mixin/base';
import mixinRisk from '../common/mixin/risk';
import mixinCommon from '../common/mixin/common';
import { cloneDeep } from "lodash";
import { Toast } from 'vant'
export default {
  name: 'collection',
  mixins: [mixinBase,mixinRisk,mixinCommon],
  data() {
    return {
        resData: {},
        msgObj:{
          holder:0,
          insured:0
        },
        requiredKeys: ['ethnicGroup', 'mobile', 'address', 'issuer', 'certBeginDate', 'certEndDate']
    }
  },
  computed: {
    ...mapState("car", ["quote","quoteRes","holderInfo","insuredInfo"]),
    plateNoName(){
      if(!(this.quote&&this.quote.vehicle)){
        return ''
      }
      if(this.quote.vehicle.plateFlag){
        return '新车未上牌'
      }
      return this.quote.vehicle.plateNo
    },
    riskDesc(){
      if(!this.quote){
        return ''
      }
      let compulsoryFlag=false
      let compulsoryNum = 0
      if(this.quote.compulsory){
        compulsoryFlag = true
        compulsoryNum = 1
      }
      let commercialFlag=false
      let commercialNum = 0
      let commercialStr=""
      if(this.quote.commercial && this.quote.commercial.riskList){
        commercialFlag = true
        const commercialList= this.quote.commercial.riskList && this.quote.commercial.riskList.map(item=>item.riskName)
        commercialNum = commercialList && commercialList.length
        commercialStr=`商业险(${commercialList && commercialList.join('、')})`
      }
      return `已选择投保${compulsoryFlag?'交强险':''}${compulsoryFlag&&commercialFlag?'、':''}${commercialFlag?commercialStr:''}，共${compulsoryNum+commercialNum}项`
    },
  },
  async created() {
    this.requiredKeys.map(item => {
      this.msgObj.holder = this.holderInfo[item] ? this.msgObj.holder : this.msgObj.holder+=1
      this.msgObj.insured = this.insuredInfo[item] ? this.msgObj.insured : this.msgObj.insured+=1
    })
  },
  mounted() {
    collection.style.height = (document.documentElement.clientHeight - 60) + 'px'
    // this.resData = this.quoteRes[this.quote.basis.insuredOrgCode]
    this.resData = this.quote
  },
  methods: {
    dayjs,
    collection () {
      if (!this.msgObj.holder && !this.msgObj.insured) {
        let holder = cloneDeep(this.holderInfo)
        let insured = cloneDeep(this.insuredInfo)
        holder.birthday = new Date(holder.birthday).getTime()
        insured.birthday = new Date(insured.birthday).getTime()
        let setInter = setInterval(() => {
            Toast.loading({
            forbidClick: true
            }, 500)
        })
        request({ url: '/afis-api-manage/invoke/core/auto/bjIdAcquisitionIn', method: 'post', data: {
          basis: { subBusinessNo: this.resData.basis.subBusinessNo || this.quoteRes[this.resData.basis.insuredOrgCode].basis.subBusinessNo },
          holder: holder,
          insured: insured
        }}).then(res => {
          Toast.success('操作成功')
          clearInterval(setInter)
          this.$router.go(-1)
        }).catch(e => {
          clearInterval(setInter)
        })
      }
    },
    toPath(path){
      this.$router.push({ path: path});
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.collection {
    background: url('./image/bg-title.png') no-repeat fixed;
    background-size: 100% 100%;
    padding: 0 8px;
    .collection-title {
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        padding: 14px 0 20px 0px;
    }
    .h-cell__content{
        padding: 6px 8px;
        font-size: $font-size-sm;
        ::v-deep .van-row {
            padding:9px 0;
        }
    }
    .h-cell{
        padding: 10px 8px;
    }
    .h-cell__value {
        color: #0066FF;
        font-size: 12px;
    }
    .base{
        .warn{
            text-indent: -4px;
            padding-left: 12px;
            font-size: $font-size-xs;
            color: $primary-color;
        }
    }
    .h-group{
        margin-bottom: 10px;
        padding: 8px;
        border-radius: $border-radius-lg;
    }
    .car-des {
        .refresh-des {
            margin-right: 4px;
        }
        .van-icon {
            font-size: 14px;
        }
    }
    .insured-msg {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .quote-result_bnt {
        height: 60px;
    }
    .quote-result_bnt--fixed {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 99;
        background-color: #fff;
        padding: 10px;
        border-top: #f7f8fa solid 2px;
    }
    .holder_bg{
    height: 74px;
    border-radius: 10px;
    background-clip:content-box;
    &--holder{
      background-image: linear-gradient(180deg, #EEFBFA 0%, #FFFFFF 100%);
      div{
        padding: 10px;
        background: url('./image/holder_bg.png') no-repeat 90% center;
        background-size: auto 70%;
      }
    }
    &--insured{
      background-image: linear-gradient(180deg, #FEF2F8 0%, #FFFFFF 100%);
      div{
        padding: 10px;
        background: url('./image/insured_bg.png') no-repeat 90% center;
        background-size: auto 70%;
      }
    }
  }
  .msg_warn{
    padding: 4px;
    font-size: $font-size-sm;
    color:$orange-dark ;
    background-color: $orange-light;
    border-radius: $border-radius-sm;
    border:$orange solid $border-width-base;
  }
  .msg_success{
    padding: 4px;
    font-size: $font-size-sm;
    color:$primary-color ;
    border-radius: $border-radius-sm;
    border:$primary-color solid $border-width-base;
  }
  .h-cell__title{
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    .van-icon{
        vertical-align: middle;
        padding-right: 5px;
    }
    }
  .h-font-people {
    display: inline-block;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
